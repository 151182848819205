import "./jquery-global";
import "./plugins/slick";
import './plugins/fancybox';
require('readmore-js');
require("./customs/hero");

$(document).ready(function () {
    $('article').readmore({ moreLink: '<a class="more">En savoir plus</a>', lessLink: false });
});
const header = document.getElementById('header');
const burger = document.getElementById('burger');

if(burger){
    burger.addEventListener('click', () => {
        header.classList.toggle('open');
    })    
}

window.addEventListener('scroll', () => {
    let y = window.scrollY;
    if (y >= 60) {
        header.classList.add('small');
    } else {
        header.classList.remove('small');
    }
});

document.querySelectorAll('li.dropdown').forEach((el) => {
    let _a;
    el.addEventListener('mouseenter', () => {
        el.classList.add('active');
        clearTimeout(_a);
    })
    el.addEventListener('mouseleave', () =>{
        _a = setTimeout(() => {
            el.classList.remove('active');
        }, 1000);
    })
})

const productsListing = document.getElementById('productsListing');

if(productsListing){
    const productsItems = document.querySelectorAll('.produits__items');

    productsItems.forEach((el) => {
        let more = el.querySelector('.produits__more');
        let moreContent = el.querySelector('.produits__more--content');

        more.addEventListener('click', () =>{
            moreContent.classList.add('active');
            more.remove();
        })
    })
}

const scrollDown = document.getElementById('scrollDown');

if(scrollDown){
    let scrolloffset = header.querySelector('.header__bottom').offsetHeight;
    scrollDown.addEventListener('click', () => {
        window.scrollTo({
            top: window.innerHeight - scrolloffset,
            left: 0,
            behavior: 'smooth'
        });
    })
}

const scrollForm = document.getElementById('scrollForm');
if(scrollForm){
    let scrolloffset = header.querySelector('.header__bottom').offsetHeight;
    scrollForm.addEventListener('click', (event) => {
        event.preventDefault();
        const formulaireSection = document.getElementById('formulaire');
        if (formulaireSection) {
            window.scrollTo({
                top: formulaireSection.offsetTop - scrolloffset,
                left: 0,
                behavior: 'smooth'
            });
        }
    });
}


// if(scrollForm){
//     let scrolloffset = header.querySelector('.header__bottom').offsetHeight;
//     scrollDown.addEventListener('click', () => {
//         window.scrollTo({
//             top: window.innerHeight - scrolloffset,
//             left: 0,
//             behavior: 'smooth'
//         });
//     })
// }


// TABS
const tabs = document.getElementById('tabs');

if(tabs){
    const tabsItems = tabs.querySelectorAll('.produit__tabsHead > a');
    const tabsContents = tabs.querySelectorAll('.produit__tabsContent > div');

    tabsItems.forEach((el, i) => {
        el.addEventListener('click', () => {
            removeAllTabs();
            el.classList.add('active');
            tabsContents[i].classList.add('active');
        })
    })

    function removeAllTabs(){
        tabsItems.forEach((el) => {
            el.classList.remove('active');
        })    
        tabsContents.forEach((el) => {
            el.classList.remove('active');
        })   
    }
}

$('#slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '#slider-nav'
  });
  $('#slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '#slider-for',
    focusOnSelect: true,
    infinite: false,
    arrows: false,
  });





const filters = document.getElementById('filters');

if(filters){
    //extend hasClass function pour qu'elle puisse prendre un array
    $.fn.extend({
        hasClasses: function (selectors) {
            var self = this;
            for (var i in selectors) {
                if ($(self).hasClass(selectors[i])) 
                    return true;
            }
            return false;
        }
    });
    
    const filter = filters.querySelectorAll('input[type="checkbox"]');
    const products = document.querySelectorAll('.listing__item');
    const container = document.querySelector('.listing__items');
    const counter = document.getElementById('counter').querySelector('strong');

    let clsToFilter = [];
    let activeCpt = 0;

    filter.forEach((el) => {
        el.addEventListener('change', filterAll);
    })
    function filterAll(){
        clsToFilter = [];
        activeCpt = 0;
        filter.forEach((el) => {
            if(el.checked){
                clsToFilter.push(el.id);
            }
        });

        products.forEach((el) => {
            el.classList.remove('active');
            el.remove();
        })

        if(clsToFilter.length == 0){
            products.forEach((el) => {
                container.appendChild(el);
                activeCpt++;
                setTimeout(() => {
                    el.classList.add('active');
                }, 100)
            })
        } else{
            products.forEach((el) => {
                for(var i = 0; i < clsToFilter.length; i++){
                    if(el.classList.contains(clsToFilter[i])){
                        container.appendChild(el);
                        activeCpt++;
                        setTimeout(() => {
                            el.classList.add('active');
                        }, 100)
                    }
                }
            })
        }

        counter.innerHTML = activeCpt;
    }
}

require("./woocommerce/cart");
