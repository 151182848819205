jQuery(function($) {

    $(document.body).on('added_to_cart removed_from_cart wc-blocks_added_to_cart wc-blocks_removed_from_cart', function() {
        // AJAX request to get the current cart count
        console.log('cart updated');
          // Retrieve the total number of items in the cart
          var totalItems = $( '.cart_totals .cart-subtotal td .amount' ).text();
        
          // Log the message and the total items in the console
          console.log( 'Cart totals updated. Total items in cart: ' + totalItems );
        $.ajax({
            url: wc_add_to_cart_params.ajax_url,
            type: 'POST',
            data: {
                'action': 'update_cart_count'
            },
            success: function(data) {
                $('.cart-count').text(data);
            }
        });
    });
});
