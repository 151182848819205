import { gsap } from "gsap";
document.addEventListener("DOMContentLoaded", function () {
  var slides = document.querySelectorAll(".slide-data");
  var slideContent = [];

  slides.forEach(function (slide, index) {
    slideContent.push({
      title: slide.getAttribute("data-title"),
      link: slide.getAttribute("data-link"),
      linkText: slide.getAttribute("data-link-text"),
      hero_img: slide.getAttribute("data-img"),
    });

    const dot = document.createElement("button");
    dot.classList.add("carousel-dot");
    dot.setAttribute("data-index", index);
    dot.addEventListener("click", function () {
      jumpToSlide(index);
    });
    document.querySelector(".carousel-dots").appendChild(dot);
  });

  let currentSlide = 0;
  const totalSlides = slideContent.length;
  let isAnimating = false;
  let slideInterval;

  function updateContent(slideIndex) {
    if (isAnimating) return;
    isAnimating = true;

    const content = slideContent[slideIndex];
    const titleElement = document.querySelector(".hero__left h2");
    const linkElement = document.querySelector(".hero__left .link");
    const linkTextElement = document.querySelector(".hero__left .link span");
    const imgElement = document.querySelector(".hero__img");

    titleElement.innerHTML = content.title;
    linkElement.href = content.link;
    linkTextElement.textContent = content.linkText;
    imgElement.src = content.hero_img;

    gsap.fromTo(
      imgElement,
      { opacity: 0, y: -80 },
      { opacity: 1, y: 0, duration: 0.3, ease: "power1.out", delay: 0.2 }
    );
    gsap.fromTo(
      titleElement,
      { opacity: 0, y: -20 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power1.out",
        onComplete: function () {
          isAnimating = false;
        },
      }
    );
    gsap.fromTo(
      linkElement,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 0.5, ease: "power1.out", delay: 0.2 }
    );
    highlightDot(slideIndex);
  }

  function highlightDot(activeIndex) {
    const dots = document.querySelectorAll(".carousel-dot");
    dots.forEach((dot, index) => {
      if (index === activeIndex) {
        dot.classList.add("active");
      } else {
        dot.classList.remove("active");
      }
    });
  }

  function resetSlideTimer() {
    clearInterval(slideInterval);
    slideInterval = setInterval(nextSlide, 5000);
  }

  function nextSlide() {
    if (isAnimating) return;
    currentSlide = (currentSlide + 1) % totalSlides;
    updateContent(currentSlide);
    resetSlideTimer();
  }

  function prevSlide() {
    if (isAnimating) return;
    currentSlide = (currentSlide - 1 + totalSlides) % totalSlides;
    updateContent(currentSlide);
    resetSlideTimer();
  }

  function jumpToSlide(slideIndex) {
    if (isAnimating) return;
    currentSlide = slideIndex;
    updateContent(currentSlide);
    resetSlideTimer();
  }

  document.getElementById("next").addEventListener("click", nextSlide);
  document.getElementById("prev").addEventListener("click", prevSlide);
 
  updateContent(0);
  resetSlideTimer();
});
